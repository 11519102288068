import React from "react"

import SEO from "../components/seo"
import FullpageSection from "../components/fullpageSection"

const ServerErrorPage = () => (
  <main>
    <SEO
      title="Ceres non c'è"
      description="Errore 500: Errore del server"
    />

    <FullpageSection
      normalBackground={true}
      whichTitle={"h1"}
      copyTitle={"Ceres non c'è"}
      copyText={"Ci dispiace ma c'è un errore con il server. Prova a tornare in Homepage"}
      backgroundImage={""}
      haveCTA={true}
      buttonLinkTo={"/"}
      buttonLinkLabel={"Homepage"}
    />

  </main>
);

export default ServerErrorPage
